<template>
    <div id="features">
      <div class="container-fluid">
        <!-- Filters Section -->
        <div class="filters-section">
          <iq-card class="w-100">
            <template v-slot:body>
              <div class="filter-container" @change="filtering">
                <b-form-input
                  type="text"
                  :placeholder="$t('title')"
                  v-model="filterData['title']"
                  required
                ></b-form-input>
              </div>
            </template>
        </iq-card>
        <iq-card>
            <b-row >
                <b-col v-for="(item, index) in accountants.data" :key="index" md="4" >
                    <div >
                        <router-link :to="{ name: 'hotel-transactions', params: { id: item.id } }">
                        <b-card
                            :img-src="item.image"
                            img-alt="Image"
                            img-top
                            tag="article"
                            style="max-width: 25rem;padding: 1rem 1rem 1rem 1rem;height: 25rem;box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);margin: 1rem 1rem;"
                            class="mb-2"
                        >
                            <b-card-text>
                                <p>
                                    <b>Hotel : </b><span>{{ item.title }}</span>
                                    <br>
                                    <b>Active Reservations : </b><span>{{ item.active_reservations_count }}</span>
                                    <br>
                                    <b>All Reservations : </b><span>{{ item.all_reservations_count }}</span>
                                    <br>
                                    <b>Total Price : </b><span> {{ item.active_reservations_sum_total_price?"$" + item.active_reservations_sum_total_price:0 }}</span>
                                </p>
                            </b-card-text>
                        </b-card>
                    </router-link>
                    </div>
                </b-col>
            </b-row>
          </iq-card>
        </div>
        <!-- Pagination Section -->
        <div class="pagination">
          <iq-card class="w-100 d-flex justify-content-center">
            <template v-slot:body>
              <b-pagination
                  v-model="filterData.page"
                  :total-rows="accountants.total"
                  :per-page="accountants.per_page"
              ></b-pagination>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </template>
<script>
export default {
  name: 'Accountant',
  data () {
    return {
      filterData: {
        page: 1,
        ar_name: '',
        en_name: '',
        kr_name: ''
      },
      payload: {
        ar_name: '',
        en_name: '',
        kr_name: '',
        feature_id: ''
      }
    }
  },
  methods: {
    filtering () {
      this.filterData.page = 1
      this.getAccountants(this.filterData)
    },
    closeModal (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.payload = this.resetObject(this.payload)
    }
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getAccountants(this.filterData)
      },
      deep: true
    }
  },
  mounted () {
    this.authUserPermissions();
    this.getAccountants()
  }
}
</script>
<style scoped>
.card-img-top{
    height: 16rem;
}
</style>
